<template>
  <div class="sub-header">
    <div class="sub-header__wrap container">
      <h1 class="sub-header__title main-title">Заказы</h1>
      <div v-if="$route.params.type === 'costs'" class="sub-header__right flex">
        <!--                <button class="sub-header__btn default-btn flex">-->
        <!--                    <img class="sub-header__icon" src="@/assets/png/database-download.png" alt="">-->
        <!--                    Экспорт-->
        <!--                </button>-->
        <p class="sub-header__text">1-50 из 123</p>
        <button class="sub-header__box">
          <img class="sub-header__box-icon" src="@/assets/png/database-icon.png" alt="" />
        </button>
        <button class="sub-header__box">
          <img class="sub-header__box-icon" src="@/assets/png/database-icon-active.png" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Аналитика",
      showBlock: false,
    };
  },
  watch: {
    "$route.params.type"(to) {
      this.changeRout(to);
    },
  },
  mounted() {
    this.changeRout(this.$route.params.type);
  },
  methods: {
    changeRout(n) {
      switch (n) {
        case "costs":
          this.title = "Расходы ТО";
          break;
        case "logistics":
          this.title = "Аналитика логистики";
          break;
        default:
          this.title = "Аналитика";
          break;
      }
    },
  },
};
</script>

<style scoped>
.sub-header {
  padding: 16px 0;
  background-color: #fff;
  border: 1px solid #f2f2f8;
  box-sizing: border-box;
  height: 80px;
}
.sub-header__block {
  margin: 10px 0 20px 0;
  height: 40px;
}
.sub-header__left {
  margin: 20px 0 0 0;
}
.sub-header__btn-tabs {
  padding: 9px;
  height: 36px;
  background: transparent;
  border-radius: 8px;
  font-weight: bold;
  line-height: 16px;
  outline: none;
}
.sub-header__btn-tabs:not(:last-child) {
  margin-right: 10px;
}
.sub-header__active-tabs {
  background: #e0e9fa;
  color: #306cdc;
}
.sub-header__text {
  margin: 0 20px 0 25px;
  color: #707070;
}
.sub-header__icon {
  margin-right: 12px;
}
.sub-header__box {
  min-width: 36px;
  height: 36px;
  line-height: 16px;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  box-sizing: border-box;
  border-radius: 8px;
}
.sub-header__box:not(:last-child) {
  margin-right: 18px;
}
</style>
